@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap');

/* Brawl Stars Web Font */
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');

:root {
  --glow-color: rgb(255, 177, 43);
}

.lilita-one-regular {
  font-family: "Lilita One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: rgb(255, 202, 96);
  font-family: "Exo 2", sans-serif;
  font-optical-sizing: auto;
  font-style:normal;
  font-family: lilita one, roboto, sans-serif;
  overflow: hidden;

  font-family: "Lilita One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

div.ID input {
  margin-left: .5em;
  margin-right: .5em;
}

button {
  border-radius: .75em;
  background-color: rgb(255, 166, 0);
  color: rgb(161, 0, 193);
}


.page-link {
  width: 80%;
  height: 10em;
  border: .5em solid black;
  border-radius: 3em;
  padding: 1.5em;
  overflow: hidden;
  cursor: pointer;
  margin-top: 1em;
  margin-bottom: 1em;
  background-color: rgb(255, 186, 58);
  z-index: -1;
}

.page-link .name {
  font-size: 3em;
  z-index: 2;
  transition-duration: 1.5s;
}

.page-link img {
  position: relative;
  top: -4em;
  left: 3em;
  width: 20em;
  rotate: 30deg;
  z-index: -1;
  transition-duration: 1.5s;
  z-index: 1;
  animation: pin-dance 2s infinite ease-in-out;
}

@keyframes pin-dance {
  0% {
    transform: rotate(1deg);
  }
  50% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(1deg);
  }
}

.page-link img.map {
  left: 9em;
  top: -6em;
  rotate: -40deg;
  width: 17em;
}

.page-link:hover {
  background-color: rgb(255, 166, 0);
  animation: page-link-glow 2.5s infinite;
}

.page-link:hover img {
  top: -9em;
  left: 4em;
  width: 20em;
  rotate: -20deg;
}

.page-link:hover img.map {
  top: -6em;
  left: 3em;
  width: 20em;
  rotate: 20deg;
}

.page-link:hover .name {
  font-size: 2em;
}

@keyframes page-link-glow {
  0%{
    box-shadow: 0px 0px 12px 5px var(--glow-color);
  }
  50% {
    box-shadow: 0px 0px 12px 15px var(--glow-color);
  }
  100% {
    box-shadow: 0px 0px 12px 5px var(--glow-color);
  }
}


.brawlers-list-page {
  
}

.scrollGradient {
  background: 
    linear-gradient(#efcaa7 75%, rgba(239,202,167, 0)),
    linear-gradient(rgba(239,202,167, 0), #efcaa7 75%) 0 100%,
    radial-gradient(farthest-side at 75% 0, rgba(34,34,34, 0.5), rgba(0,0,0,0)),
    radial-gradient(farthest-side at 75% 100%, rgba(34,34,34, 0.5), rgba(0,0,0,0)) 0 100%;
  background-color: #efcaa7;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size: 100% 147px, 100% 147px, 100% 49px, 100% 49px;
}

.top-header {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0;
  background-color: rgb(255, 202, 96);
  box-shadow: 0px 8px 12px 0px rgba(255, 202, 96);

  /* position: fixed;
  top: 0;
  background-color: rgb(255, 202, 96);
  z-index: 10;
  width: 90%;
  height: 10em;
  padding-bottom: 5%;
  padding-bottom: 0;
  box-shadow: 0px 8px 12px 0px rgba(255, 202, 96); */
}

.title {
  display: flex;
  padding-top: .5em;
  font-size: 2em;
  padding: .5em;
  padding-bottom: 0;
}

div.brawlers-list-page .title {
}

div.brawler .title, div.map .title, .landing-page .title {
  width: 100%;
  background-color: rgb(255, 202, 96);
  box-shadow: 0px 8px 12px 0px rgba(255, 202, 96);
  padding-bottom: .5em;
  position: fixed;
  top: 0;
}

.title .divider {
  width: .1em;
  height: 1.25em;
  background-color: black;
  border-radius: 1em;
  margin-inline: .5em;
}

.back-arrow {
  width: 1.25em;
  border-radius: 100%;
  animation: back-arrow-slide 2s;
  transition-duration: 5s;
  transform: translateX(0);
  transition: transform 2s ease-in-out;
}

.back-arrow:hover {
  cursor: pointer;
  animation: back-arrow-slide 2s infinite;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

@keyframes back-arrow-slide {
  0% {
    transform: translate(0);
  }
  50% {
    transform: translate(-5px);
  }
}

.back-arrow:active {
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.main-body {
  /* padding: 5%;
  overflow: hidden;
  margin-top: 4em; */
}

input.search-bar {
  margin-top: 1em;
  font-family: lilita one, roboto, sans-serif;
  color: #000000;
  width: 90%;
  margin-left: 1.5em;
  box-sizing: border-box;
  border: .5em solid #a26700;
  border-radius: 2em;
  font-size: 1.3em;
  background-color: rgb(255, 171, 14);
  background-image: url('/src/Resources/search4.png');
  background-position: 1em .55em;
  background-size: 1.5em;
  background-repeat: no-repeat;
  padding: .7em 0em .75em 3em;
}


div.brawlers-list {
  /* overflow-y: scroll; */
  /* columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2; */
}


div.card {
  margin: 2em;
  padding: 1em;
  background-color: rgb(255, 202, 96);
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: .5em;
  cursor: pointer;
}

.card:hover {
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.75);
}

.card:hover .little-brawler-icon {
  width: 5em;
}

.brawlers-list-page .card:hover .little-brawler-icon {
  animation: bounce-and-turn 1.5s linear infinite;
}

.card:hover .name {
  font-size: 2em;
}

@keyframes swinging {
  0%, 100% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-10deg);
  }
}

@keyframes bounce {
  0% {
    transform: translate(0, 0em);
  }
  50% {
    transform: translate(0, -2em);
  }
  100% {
    transform: translate(0, 0em);
  }
}

@keyframes bounce-and-turn {
  0%, 50% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-.5em, -1em) rotate(-10deg);
  }
  75% {
    transform: translate(.5em, -1em) rotate(10deg);
  }
  100% {
    transform: translate(0, 0em);
  }
}

@keyframes card-pin-dance {
  25% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-10deg);
  }
}

div.card .top {
  padding-bottom: 1em;
  display: flex;
}

img.little-brawler-icon {
  width: 2.5em;
  padding-right: 1em;
  transition-duration: 2s;
}

div.card .name {
  /* padding-top: .5em; */
  margin-top: auto;
  font-size: 1.5em;
  font-weight: 800;
  transition-duration: 2s;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 3em;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: .2em .2em;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

div.star-power-section, div.gadget-section {
  border-radius: 1em;
  border: 5px solid black;
  padding: .5em;
  margin: .5em;
}


div.star-power, div.gadget {
  position: relative;
  display: block;
  cursor: pointer;
}

/* input, div.star-power .details, div.gadget .details {
  display: none;
} */

input:checked~div.details {
  display: block;
}



/*hide the inputs/checkmarks and submenu*/
/* input, ul.submenu {
  display: none;
} */

/*position the label*/
label {
  position: relative;
  display: block;
  cursor: pointer;
}

/*show the submenu when input is checked*/
input:checked~ul.submenu {
  display: block;
}





/* Brawler Page */

.brawler .main-body, .map .main-body {
  padding: 1em;
}

.brawler-description {
  padding: 1em;
}


.star-power-image, .gadget-image {
  width: 4em;
  filter: drop-shadow(0px 0px 4px #7c5000);
}

.star-power-image:hover, .gadget-image:hover {
  filter: drop-shadow(10px 10px 4px #7c5000);
}

.star-power-image:active, .gadget-image:active {
  transform: translate(2px, 2px);
  filter: drop-shadow(5px 5px 4px #7c5000);
}



div.star-powers-view, div.gadgets-view {
  border-radius: 1em;
  border: 5px solid black;
  padding: .5em;
  margin: .5em;
  display: flex;
}

div.star-powers-view .star-powers, div.gadgets-view .gadgets {
  display: block;
  max-width: 5em;
}

div.star-powers-view .details, div.gadgets-view .details {
  margin-left: 1em;
}

div.star-powers-view .name, div.gadgets-view .name {
  font-weight: 600;
  font-size: 1.5em;
}

div.star-powers-view .description, div.gadgets-view .description {
  display: inline-block;
  padding-top: 1em;
  padding-left: 1em;
}

div.star-powers-view .star-power-image.selected, div.gadgets-view .gadget-image.selected {
  filter: drop-shadow(0px 0px 20px #ffcf4b);
}

img.brawler-icon {
  width: 1.25em;
  margin-right: .5em;
  border-radius: .5em;
}

img.brawler-pin {
  width: 10em;
}

img.brawler-pin:hover, img.brawler-icon:hover {
  cursor: pointer;
  animation: swinging 1.5s linear infinite;
}





.scroll-to-top {
  display: none; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 15px; /* Some padding */
  border-radius: 10px; /* Rounded corners */
  font-size: 18px; /* Increase font size */
}

.scroll-to-top.show {
  display: block;
}

.scroll-to-top:hover {
  background-color: #d67600; /* Add a dark-grey background on hover */
}







.brawlers-list {
  height: 100vh; /* Ensure the container takes full viewport height */
  overflow-y: auto; /* Allow vertical scrolling */
  scroll-snap-type: y mandatory; /* Enable vertical scroll snap */
  -webkit-overflow-scrolling: touch; /* Enables smooth scrolling on iOS */
  padding-top: 1em; /* Control the offset by adding padding */
  scroll-padding: 1em;
}

.card {
  scroll-snap-align: start; /* Snaps to the start of the container */
  margin: 2em;
  padding: 1em;
  background-color: rgb(255, 202, 96);
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: .5em;
  cursor: pointer;
}

.card:hover {
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.75);
}





.brawlers-list-page.top-header {
  padding-bottom: 0em;
}





html, body {
  margin: 0;
}

.brawlers, .maps {
  position: relative;
  height: 100vh; /* Ensure the container takes full viewport height */
}

.brawlers-list, .maps-list {
  height: calc(100vh - 10.5em); /* Adjust to fit within the height available, minus the header and other elements */
  overflow-y: auto; /* Allow vertical scrolling */
  scroll-snap-type: y mandatory; /* Enable vertical scroll snap */
  -webkit-overflow-scrolling: touch; /* Enables smooth scrolling on iOS */
  margin-top: 4em;
  scroll-padding: 2em;
}

.card {
  scroll-snap-align: start; /* Snaps to the start of the container */
  margin: 2em;
  padding: 1em;
  background-color: rgb(255, 202, 96);
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: .5em;
  cursor: pointer;
}

.scroll-to-top {
  position: fixed;
  bottom: 2em;
  right: 2em;
  padding: 1em;
  background-color: #ffca60;
  border: none;
  border-radius: .5em;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  cursor: pointer;
  display: none;
}

.scroll-to-top.show {
  display: block;
}

.main-body {
  padding: 1.5em;
}

.card:hover {
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.75);
}

.main-body {
  padding: 1.5em;
  margin-top: 4em;
}


/* .brawlers-list::-webkit-scrollbar {
  width: 10px;
}
.brawlers-list::-webkit-scrollbar-track-piece {
  border: solid 1px black;
} */
/* .brawlers-list::-webkit-scrollbar-thumb {
  background: black;
} */

/* .brawlers-list::-webkit-scrollbar-button:end:increment {
  margin-inline-start: 10em;
  height: 20em;
  display: block;
  background: transparent;
  position: absolute;
  bottom: 0;
} */




.detail-name {
  display: inline;
  font-weight: 900;
}

.detail-description {
  display: inline;
  background-color: transparent;
}

img.mini-map {
  width: 10em;
}

.map .main-body {
  font-size: 1.5em;
}



body {
  overflow-y: auto;
}

body:has(.search-bar) {
  overflow: hidden;
}




.game-mode-icon {
  /* -webkit-filter: drop-shadow(5px 5px 5em #222); */
  /* filter: drop-shadow(5px 5px 1em #222); */
}


/* .maps-list-page .card:hover .little-brawler-icon {
  transform: rotate(90deg) translate(-1em, -4em);
}

.maps-list-page .card:hover .little-brawler-icon {
  width: 5em;
} */

/* .maps-list-page .card:hover .name {
  font-size: 2em;
  width: 50em;
  position: relative;
  left: -3em;
} */

@keyframes spinning-icon {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-20deg); } /* Rotate backwards a bit */
  100% { transform: rotate(360deg); }
}

.loading-icon {
  animation: spinning-icon 2s infinite;
}


.player-brawler-row {
  border-radius: .2em;
  transition: box-shadow 0.3s ease;
}

.player-brawler-row:hover {
  cursor: pointer;
}